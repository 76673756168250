/* eslint-disable camelcase */
import { PlusCircleOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../components/Buttons/Button';
import PrimaryCard from '../../components/Cards/PrimaryCard';
import Form from '../../components/Form/Form';
import LabelInput from '../../components/Form/LabelInput';
import Loading from '../../components/loading';
import { getCityAndStateDataFromPincode, indianStates } from '../../utils';
import Api from '../../utils/ApiUtils/Api';
import CreateCustomer from '../Customers/createCustomer';
import Notification from '../../components/Notifications';

const NomineeForm = (props) => {
	const { current, setCurrent, nomineeDetails } = props;
	const [nominee, setNominee] = useState({
		first_name: '',
		last_name: '',
		mobile_number: '',
		addr_line_1: '',
		addr_line_2: '',
		city: 'Chennai',
		state: 'Tamil Nadu',
		pincode: '',
	});

	useEffect(() => {
		setNominee((prev) => ({
			...prev,
			first_name: nomineeDetails?.first_name || '',
			last_name: nomineeDetails?.last_name || '',
			mobile_number: nomineeDetails?.mobile_number || '',
			addr_line_1: nomineeDetails?.userAddress?.addr_line_1 || '',
			addr_line_2: nomineeDetails?.userAddress?.addr_line_2 || '',
			city: nomineeDetails?.userAddress?.city || 'Chennai',
			state: nomineeDetails?.userAddress?.state || 'Tamil Nadu',
			pincode: nomineeDetails?.userAddress?.pincode || '',
		}));
	}, [nomineeDetails]);

	const [state, setStateHelper] = useState({
		isSubmitting: false,
		isError: false,
	});
	const { id: visitId } = useParams();

	const setState = (changes) => {
		setStateHelper((prevState) => ({
			...prevState,
			...changes,
		}));
	};

	const handleChange = (field, value) => {
		setState({ [field]: value });
	};
	const handleSubmit = () => {
		const data = {
			input: {
				first_name: nominee?.first_name,
				last_name: nominee?.last_name,
				mobile_number: `+91${nominee?.mobile_number}`,
				address: {
					addr_line_1: nominee?.addr_line_1,
					addr_line_2: nominee?.addr_line_2 || '',
					city: nominee?.city,
					state: nominee?.state,
					pincode: nominee?.pincode,
					lat: 0,
					long: 0,
				},
			},
		};
		setState({ isSubmitting: true });
		Api.post(`safe/visit/${visitId}/nominee`, data)
			.then((res) => {
				setState({ isSubmitting: false });
				Notification.create('success', 'Nominee Added Succefully');
				setCurrent(current + 1);
			})
			.catch((err) => setState({ isSubmitting: false, isError: true }));
	};

	return (
		<div className='flex flex-col lg:flex-row lg:space-x-5'>
			<div className='text-header-3 p-5 lg:w-1/5 uppercase'>
				Nominee Details
			</div>
			<Form className='space-y-10' onSubmit={handleSubmit}>
				<PrimaryCard className='p-10 space-y-5'>
					<div className='flex w-3/4 gap-5 flex-wrap'>
						<LabelInput
							type='text'
							label='First Name'
							rootClassName='w-full md:w-auto'
							placeholder='Enter First Name'
							value={nominee?.first_name ?? ''}
							required
							onChange={(e) => {
								setNominee((prev) => ({
									...prev,
									first_name: e.target.value,
								}));
							}}
						/>
						<LabelInput
							type='text'
							label='Last Name'
							rootClassName='w-full md:w-auto'
							placeholder='Enter Last Name'
							value={nominee?.last_name ?? ''}
							required
							onChange={(e) => {
								setNominee((prev) => ({
									...prev,
									last_name: e.target.value,
								}));
							}}
						/>
					</div>
					<LabelInput
						type='numberText'
						label='Mobile Number'
						placeholder='Enter mobile number'
						inputClassName='w-full md:w-1/2'
						value={nominee?.mobile_number ?? ''}
						onChange={(value) => {
							setNominee((prev) => ({
								...prev,
								mobile_number: value,
							}));
						}}
						required
						maxLength={10}
					/>
					<div className='flex flex-col space-y-2'>
						<p className='label'>Location</p>
						<LabelInput
							type='text'
							placeholder='Full Address'
							value={nominee?.addr_line_1 ?? ''}
							required
							onChange={(e) => {
								setNominee((prev) => ({
									...prev,
									addr_line_1: e.target.value,
								}));
							}}
						/>
						<div className='flex gap-5 flex-wrap'>
							<LabelInput
								type='text'
								placeholder='City'
								value={nominee?.city ?? ''}
								required
								onChange={(e) => {
									setNominee((prev) => ({
										...prev,
										city: e.target.value,
									}));
								}}
							/>
							<LabelInput
								type='numberText'
								placeholder='Pincode'
								value={nominee?.pincode ?? ''}
								required
								onChange={async (e) => {
									if (e.length === 6) {
										const { stateName } = await getCityAndStateDataFromPincode(
											e
										);
										if (stateName) {
											setNominee((prev) => ({
												...prev,
												state: stateName,
											}));
										}
									}

									setNominee((prev) => ({
										...prev,
										pincode: e,
									}));
								}}
								maxLength={6}
							/>
						</div>
					</div>
				</PrimaryCard>
				<div className='text-center w-full'>
					<Button
						type='submit'
						variant='cta'
						isLoading={state?.isSubmitting}
						className='w-full md:w-3/4 lg:w-1/2 mx-auto'
					>
						Choose Locker To Add
					</Button>
				</div>
			</Form>
		</div>
	);
};

export default function AddCustomerDetails(props) {
	const { current, setCurrent, state: stepperState } = props;
	const [state, setStateHelper] = useState({
		nominee: false,
		isLoading: false,
		isError: false,
		customerTypes: 1,
		isSubmitting: false,
	});
	const { id: visit_id } = useParams();
	const setState = (changes) => {
		setStateHelper((prevState) => ({
			...prevState,
			...changes,
		}));
	};

	const getUserDetails = useCallback(async (user_id) => {
		try {
			const { data } = await Api.get(`/user/safe-user/${user_id}`);
			setState({
				customerTypes:
					Object.keys(data?.visitors?.tertiaryUser || {}).length > 0
						? 3
						: Object.keys(data?.visitors?.secondaryUser || {}).length > 0
						? 2
						: 1,
				primary_customer: {
					first_name: data?.visitors?.primaryUser?.first_name,
					last_name: data?.visitors?.primaryUser?.last_name,
					mobile_number:
						data?.visitors?.primaryUser?.mobile_number?.substring(3),
					email: data?.visitors?.primaryUser?.email_id,
					addr_line_1: data?.visitors?.primaryUser?.userAddress?.addr_line_1,
					addr_line_2: data?.visitors?.primaryUser?.userAddress?.addr_line_2 || '',
					city: data?.visitors?.primaryUser?.userAddress?.city || 'Chennai',
					state:
						data?.visitors?.primaryUser?.userAddress?.state || 'Tamil Nadu',
					pincode: data?.visitors?.primaryUser?.userAddress?.pincode,
				},
				secondary_customer: {
					first_name: data?.visitors?.secondaryUser?.first_name,
					last_name: data?.visitors?.secondaryUser?.last_name,
					mobile_number:
						data?.visitors?.secondaryUser?.mobile_number?.substring(3),
					email: data?.visitors?.secondaryUser?.email_id,
					addr_line_1: data?.visitors?.secondaryUser?.userAddress?.addr_line_1,
					addr_line_2: data?.visitors?.secondaryUser?.userAddress?.addr_line_2 || '',
					city: data?.visitors?.secondaryUser?.userAddress?.city || 'Chennai',
					state:
						data?.visitors?.secondaryUser?.userAddress?.state || 'Tamil Nadu',
					pincode: data?.visitors?.secondaryUser?.userAddress?.pincode,
				},
				tertiary_customer: {
					first_name: data?.visitors?.tertiaryUser?.first_name,
					last_name: data?.visitors?.tertiaryUser?.last_name,
					mobile_number:
						data?.visitors?.tertiaryUser?.mobile_number?.substring(3),
					email: data?.visitors?.tertiaryUser?.email_id,
					addr_line_1: data?.visitors?.tertiaryUser?.userAddress?.addr_line_1,
					addr_line_2: data?.visitors?.tertiaryUser?.userAddress?.addr_line_2 || '',
					city: data?.visitors?.tertiaryUser?.userAddress?.city || 'Chennai',
					state:
						data?.visitors?.tertiaryUser?.userAddress?.state || 'Tamil Nadu',
					pincode: data?.visitors?.tertiaryUser?.userAddress?.pincode,
				},
				nomineeDetails: data?.visitors?.nomineeUser,
			});
		} catch (err) {
			setState({ isLoading: false, isError: true });
		}
	}, []);

	useEffect(() => {
		if (stepperState?.user_id) {
			getUserDetails(stepperState?.user_id);
		}
	}, [stepperState?.user_id]);

	const onChange = (customerType, field, value) => {
		let data = state[customerType];
		console.log(customerType, field, value, 'gggggggg');
		if (!data)
			data = {
				city: 'Chennai',
				state: 'Tamil Nadu',
			};
		data[field] = value;
		const changes = {};
		changes[customerType] = data;
		setState(changes);
	};

	const validate = () => {
		const {
			primary_customer: primaryCustomer,
			secondary_customer: secondaryCustomer,
			tertiary_customer: tertiaryCustomer,
			customerTypes,
		} = state;

		if (
			primaryCustomer?.recent_photo?.length < 1 ||
			!primaryCustomer?.id_proof?.front_side ||
			!primaryCustomer?.address_proof?.front_side
		) {
			setState({ formError: 'Please check all the fields' });
			return false;
		}
		if (
			customerTypes === 2 &&
			(secondaryCustomer?.recent_photo?.length < 1 ||
				!secondaryCustomer?.id_proof?.front_side ||
				!secondaryCustomer?.address_proof?.front_side)
		) {
			setState({ formError: 'Please check all the fields' });
			return false;
		}

		if (
			customerTypes === 3 &&
			(tertiaryCustomer?.recent_photo?.length < 1 ||
				!tertiaryCustomer?.id_proof?.front_side ||
				!tertiaryCustomer?.address_proof?.front_side)
		) {
			setState({ formError: 'Please check all the fields' });
			return false;
		}
		return true;
	};

	const getImagePayload = (data) => {
		const res = [];
		res.push({
			type: `${data.image_type} Front`.toLowerCase().split(' ').join('_'),
			url: data.front_side,
		});
		if (data.back_side) {
			res.push({
				type: `${data.image_type} Back`.toLowerCase().split(' ').join('_'),
				url: data.back_side,
			});
		}
		return res;
	};

	const submitHandler = () => {
		const { customerTypes } = state;
		const payLoad = {};
		if (customerTypes >= 1) {
			payLoad.data = {
				primary: {
					first_name: state.primary_customer?.first_name,
					last_name: state.primary_customer?.last_name,
					mobile_number: `+91${state.primary_customer?.mobile_number}`,
					email_id: state.primary_customer?.email,
					addr_line_1: state.primary_customer?.addr_line_1,
					addr_line_2: state.primary_customer?.addr_line_2 || '',
					state: state.primary_customer?.state,
					city: state.primary_customer?.city,
					pin: state.primary_customer?.pincode,
				},
			};
			payLoad.data.customerSource = state?.primary_customer?.customerSource;
			payLoad.data.visitId = visit_id;
			payLoad.data.primaryIdProof = getImagePayload(
				state?.primary_customer?.id_proof
			);
			payLoad.data.primaryAddressProof = getImagePayload(
				state?.primary_customer?.address_proof
			);
			payLoad.data.primaryRecentPhoto = state?.primary_customer?.recent_photo;
		}
		if (customerTypes >= 2) {
			payLoad.data = {
				...payLoad.data,
				secondary: {
					first_name: state.secondary_customer?.first_name,
					last_name: state.secondary_customer?.last_name,
					mobile_number: `+91${state.secondary_customer?.mobile_number}`,
					email_id: state.secondary_customer?.email,
					addr_line_1: state.secondary_customer?.addr_line_1,
					addr_line_2: state.secondary_customer?.addr_line_2 || '',
					state: state.secondary_customer?.state,
					city: state.secondary_customer?.city,
					pin: state.secondary_customer?.pincode,
				},
			};
			payLoad.data.secondaryIdProof = getImagePayload(
				state?.secondary_customer?.id_proof
			);
			payLoad.data.secondaryAddressProof = getImagePayload(
				state?.secondary_customer?.address_proof
			);
			payLoad.data.secondaryRecentPhoto =
				state?.secondary_customer?.recent_photo;
		}
		if (customerTypes >= 3) {
			payLoad.data = {
				...payLoad.data,
				tertiary: {
					first_name: state.tertiary_customer?.first_name,
					last_name: state.tertiary_customer?.last_name,
					mobile_number: `+91${state.tertiary_customer?.mobile_number}`,
					email_id: state.tertiary_customer?.email,
					addr_line_1: state.tertiary_customer?.addr_line_1,
					addr_line_2: state.tertiary_customer?.addr_line_2 || '',
					state: state.tertiary_customer?.state,
					city: state.tertiary_customer?.city,
					pin: state.tertiary_customer?.pincode,
				},
			};
			payLoad.data.tertiaryIdProof = getImagePayload(
				state?.tertiary_customer?.id_proof
			);
			payLoad.data.tertiaryAddressProof = getImagePayload(
				state?.tertiary_customer?.address_proof
			);
			payLoad.data.tertiaryRecentPhoto = state?.tertiary_customer?.recent_photo;
		}

		setState({ isSubmitting: true, isError: false });
		Api.post('safe/add-customer', payLoad)
			.then((data) => {
				Notification.create('success', 'Customer is created Succefully');
				setState({ isSubmitting: false, nominee: true });
			})
			.catch((err) => setState({ isSubmitting: false, isError: true }));
	};

	const { isLoading, isError, nominee, customerTypes, isSubmitting } = state;

	if (isLoading) return <Loading />;
	if (isError)
		return (
			<div className='flex flex-col space-y-2 font-bold h-1/2 items-center justify-center'>
				<p>Something Went Wrong</p>
				<p>Please Try Again</p>
				<Button variant='primary' onClick={() => setState({ isError: false })}>
					Retry
				</Button>
			</div>
		);
	return (
		<>
			{nominee ||
			stepperState?.intermediate_status === 'SAFE_CUSTOMERS_ADDED' ? (
				<NomineeForm
					current={current}
					setCurrent={setCurrent}
					setLoading={(value) => setState({ isLoading: value })}
					nomineeDetails={state?.nomineeDetails}
				/>
			) : (
				<div className='flex flex-col lg:flex-row '>
					<p className='text-header-3 p-5 lg:w-1/5'>Customer Details</p>
					<Form
						customValidation={validate}
						onSubmit={submitHandler}
						className='xl:w-2/3'
					>
						<div className='space-y-10'>
							<CreateCustomer
								customerValues={state.primary_customer}
								handleChange={onChange}
								customerType='primary_customer'
							/>
							{customerTypes >= 2 && (
								<CreateCustomer
									customerValues={state.secondary_customer}
									handleChange={onChange}
									customerType='secondary_customer'
									deleteCustomer={
										customerTypes == 2
											? () => setState({ customerTypes: 1 })
											: null
									}
								/>
							)}
							{customerTypes >= 3 && (
								<CreateCustomer
									customerValues={state.tertiary_customer}
									handleChange={onChange}
									customerType='tertiary_customer'
									deleteCustomer={() => setState({ customerTypes: 2 })}
								/>
							)}
							{customerTypes < 3 && (
								<Button
									type='button'
									variant='primary'
									onClick={() => setState({ customerTypes: customerTypes + 1 })}
									className='rounded-md'
								>
									{`Add ${
										customerTypes === 1 ? 'Secondary' : 'Tertiary'
									} Customer`}
									<PlusCircleOutlined className='text-xl ml-2' />
								</Button>
							)}
							<div className='text-center w-full'>
								<Button
									type='submit'
									isLoading={isSubmitting}
									variant='cta'
									className='w-full mx-auto md:w-3/4 lg:w-1/2'
								>
									Add Nominee
								</Button>
								<p className='text-error-red mt-2'>{state?.formError ?? ''}</p>
							</div>
						</div>
					</Form>
				</div>
			)}
		</>
	);
}
